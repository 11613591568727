<template>
    <nav class="w-full sticky top-0 right-0 bg-white shadow-xl py-3 px-6 flex items-center justify-evenly gap-6 mb-7 z-50">
        <router-link :to="{name: 'home'}" exact-active-class="!text-primary-blue" class="_nav-item text-center">
            <Icon icon="fa-home"/>
            {{ $t("nav.home") }}
        </router-link>
        <router-link :to="{name: 'characters'}" active-class="!text-primary-blue"
                     class="_nav-item">
            <Icon icon="fa-users"/>
            {{ $t("nav.characters") }}
        </router-link>
        <router-link :to="{name: 'app'}" exact-active-class="!text-primary-blue" class="_nav-item text-center">
            <Icon icon="fa-info"/>
            {{ $t("nav.app") }}
        </router-link>
        <router-link :to="{name: 'ios-install'}" v-if="isSafari" exact-active-class="!text-primary-blue" class="_nav-item text-center">
            <Icon icon="ic:baseline-install-mobile"/>
            {{ $t("nav.ios") }}
        </router-link>
    </nav>
</template>

<script setup>
import {Icon} from "@iconify/vue";
import {getBrowserIdentifier} from "@/utils";

const isSafari = getBrowserIdentifier() === 'Safari'

</script>

<style scoped>
._nav-item {
    @apply flex flex-col items-center hover:text-primary-blue duration-300 text-sm no-underline text-dark-grey
}
</style>