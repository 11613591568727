<template>
    <footer class="bg-grey p-4 w-full text-white text-center">
        2024 {{ thisYear !== 2024 ? ' - ' + thisYear : '' }} Banská Štiavnica
    </footer>
</template>

<script setup>
const thisYear = new Date().getFullYear()
</script>

<style scoped>

</style>