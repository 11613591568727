export default {
    nav: {
        home: "Domov",
        characters: "Postavy",
        app: "O appke",
        ios: "Aplikácia"
    },
    install: {
        info: "Chcete pridať stránku do zariadenia?",
        confirm: "Pridať",
        decline: "Zrušiť",
        ios_1: "Kliknite na ikonu zdieľať.",
        ios_2: "Prescrollujte sa nižšie a vyberte voľbu Pridať na plochu.",
    },
    update: {
        info: "K dispozícii je nová aktualizácia.",
        confirm: "Aktualizovať",
        decline: "Zrušiť",
    },
    about: {
        headline: "Info o aplikácii",
        developers: "Vývoj aplikácie",
        authors_photo: "Autori fotografií",
        authors_text: "Autori textov",
        proposal: "Námet",
        contact_dev: "Kontaktovať vývojárov",
    },
    texts: {
        characters: "Postavy sprievodu",
        home: "Salamandrový sprievod tvorí neoddeliteľnú súčasť celoštátneho Dňa baníkov, hutníkov, naftárov a geológov, vidieť ho môžete každoročne začiatkom septembra. Dnešnému sprievodu predchádzali veselé sprievody, o ktorých sú správy už zo 17. storočia, v tej dobe bola Banská Štiavnica jedným z najbohatších a najvýznamnejších európskych miest. K ich skutočnej renesancii došlo vďaka študentom banskoštiavnickej akadémie. Študenti akadémie viedli bohatý spoločenský život, z ktorého veľa zvykov, obyčají a piesní prežilo dodnes nielen v Štiavnici. Salamandrový sprievod je však výnimkou a nemôžete ho vidieť nikde inde na svete. Pôvodne išlo o sprievod spojený so začiatkom školského roka, jedinečnou príležitosťou k zábave, zložený z členov akademickej obce „Steingrube“, ktorá bola obcou v obci, s vlastnými zákonmi, magistrátom i richtárom."
            +
            "Sprievod bol a je plný postáv, ktoré ironicky zobrazovali mestské autority, strážcov zákona, historické povolania, skutočné i mýtické postavy známe zo štiavnických ulíc a života. Neodmysliteľnou súčasťou sprievodu bola cigánska hudba. Súčasťou je aj najpôsobivejší prejav života banskoštiavnických akademikov, povestný Salamander, ktorý sa konal pri vážnych i slávnostných príležitostiach. Najdojímavejší bol pohreb profesora alebo poslucháča Akadémie, s čestnou strážou a pomalým, dôstojným pohybom mestom, svetielkujúc pri tom do noci a imitujúc pohyb salamandry škvrnitej. "
            +
            "V súčasnom sprievode sa pohybuje 500 postáv znázorňujúcich život akademikov, baníkov a obyvateľov mesta. Pred zrakmi návštevníkov sa odvíja príbeh strieborného mesta, na začiatku ktorého podľa povesti stála malá jašterička. Užite si tento unikátny sprievod plný histórie a úsmevných, zábavných i vážnych postáv zoradených do 56 obrazov.",
        characters_list: "Zoznam postáv",
        characters_list_headline: "Postavy sprievodu",
        ios_headline: "Inštalácia aplikácie v iOS",
        home_headline: "Salamandrový sprievod",
        characters_headline: "Postavy v sprievode",
        home_characters_link: "Postavy",
    },

    characters: {
        1: {
            name: "Pastieri",
            description: "Pastier s pomocníkmi nesie jaštericu posypanú drahým kovom z povesti o založení mesta."
        },
        2: {
            name: "Skrížené kladivá",
            description: "Baník v slávnostnom aušusníckom oblečení nesie symbol baníckej práce – skrížené kladivko a želiezko."
        },
        3: {
            name: "Permoníci",
            description: "Legendárni vládcovia podzemia u baníkov vzbudzovali strach, no zároveň  ich chránili pred nešťastím."
        },
        4: {
            name: "Permoníci z materskej školy",
            description: "Skupina malých baníkov a permoníkov z MŠ 1. mája."
        },
        5: {
            name: "Patrónky baníkov",
            description: "Svätá Barbora a Katarína, patrónky baníkov, chránili baníkov pred banským nešťastím."
        },
        6: {
            name: "Starý baník s fokošom a slávnostní aušusníci",
            description: "Predstavujú prosebníkov vyslaných do Viedne s prosbou o zlepšenie podmienok pre baníkov."
        },
        7: {
            name: "Kutnohorskí baníci",
            description: "Zvláštnu komunitu v meste tvorili baníci, ktorí prišli za prácou z Kutnej Hory."
        },
        8: {
            name: "Štiavnický Náco",
            description: "Náco ako typická štiavnická figúrka vtipmi a príhodami obveseľuje divákov."
        },
        9: {
            name: "Vedúci sprievodu",
            description: "Akademik s vytasenou šabľou, oblečený v dobovej uniforme."
        },
        10: {
            name: "Banícka hudba / DH Sitňanka",
            description: "Tradičná banícka hudba/dychovka. Druhá najstaršia dychová hudba na Slovensku vznikla v roku 1841 v Banskej Štiavnici, ako banícka kapela podniku Horná Bieber Štôlňa."
        },
        11: {
            name: "Salamander",
            description: "Pôvodne pohrebný sprievod baníkov, ktorý pohybom imitoval salamandru škvrnitú."
        },
        12: {
            name: "Starý lesák",
            description: "Kráča s veľkou kyticou uvitou z lístia, čečiny a kvetov, ktorú nesie obradne upevnenú na dlhej žrdi."
        },
        13: {
            name: "Valetanti / Spevokol Štiavničan",
            description: "Vo valete sa spevom lúčia poslucháči posledného ročníka akadémie s mestom, sprevádzajú ich prvoročiaci aj mladé a staré dámy. "
        },
        14: {
            name: "Večný študent",
            description: "Starý, opustený, večný študent, ktorý sa venuje viac zábave ako štúdiu, si vykračuje mestom. "
        },
        15: {
            name: "Sud",
            description: "Sud vína symbolizuje veselý študentský život, v ktorom zábava a víno zohrávali veľkú úlohu."
        },
        16: {
            name: "Chemik a chemička",
            description: "Chémia bola neoddeliteľnou súčasťou štúdia na akadémii. Dodnes stojí budova laboratórií s množstvom vetracích komínov."
        },
        17: {
            name: "Stará Cirónička",
            description: "Cirónička skutočne žila a bola v meste známa hrdými panskými kostýmami až z Viedne."
        },
        18: {
            name: "Sudca",
            description: "Študentská obec mala správu ako mesto, avšak dosť protichodného zamerania, jej súčasťou bol aj sudca."
        },
        19: {
            name: "Pisári",
            description: "Neodmysliteľní pomocníci sudcov, pisári nesúci obrovskú ceruzu, pero a kalamár."
        },
        20: {
            name: "Učenec s knihami",
            description: "Postava v sprievode pomerne nová. Predstavuje vzdelanosť, ktorou sa Štiavnica pýšila. Iniciatíva štiavnického Antikvariátiku."
        },
        21: {
            name: "Kňazi",
            description: "Postavy kňazov sa starajú o duchovný život študentskej obce, ktorá ich prácu s radosťou parodovala."
        },
        22: {
            name: "Rabín a mnísi",
            description: "Duchovného vodcu príslušníkov židovskej viery reprezentuje rabín, nesúci talmud. Mnísi pripomínajú zaniknuté štiavnické kláštory."
        },
        23: {
            name: "Kati, drábi a väzni",
            description: "Ruka spravodlivosti: hlavný kat s pomocníkmi vedú odsúdenca a väzňov. Nechýba im šibenica, ostrá sekera a klát pripravené pre divákov.  Štiavnica mala ako kráľovské mesto hrdelné právo. S ním je neodmysliteľne spätý kat a hrdelné tresty. "
        },
        24: {
            name: "Smrť",
            description: "Smrť, neoddeliteľná súčasť života nebezpečnej práce baníkov. Smrť s kosou v sprievode vytrvalo sleduje odsúdeného na smrť."
        },
        25: {
            name: "Nosič pohrebnej zástavy",
            description: "Vážnou úlohou pre baníka v uniforme bolo niesť pri pohrebe študenta či baníka zástavu."
        },
        26: {
            name: "Pohreb študenta",
            description: "Pohreb študenta bol vždy smutnou udalosťou, vyprevádzali ho jeho spolužiaci, aušusníci i bežní baníci a smútiaca rodina."
        },
        27: {
            name: "Smútiaci",
            description: "Smútiaca rodina a známy oplakávajú stratu milovanej osoby, študenta Baníckej a lesníckej akadémie. "
        },
        28: {
            name: "Duch",
            description: "Tajomná i veselá bytosť, poletuje medzi ľuďmi a straší ich."
        },
        29: {
            name: "Husári na koňoch",
            description: "Husári na koňoch v typických uniformách dokresľujú atmosféru niekdajších čias."
        },
        30: {
            name: "Trubači",
            description: "Trubači sú v banských mestách známy od 13. storočia. V Štiavnici asi najmä vďaka trúbeniu z Nového zámku (od 16. storočia)."
        },
        31: {
            name: "Komorský gróf",
            description: "Najvyššia inštancia baníctva v meste so sluhom, ktorú akademici ironizovali a parodovali."
        },
        32: {
            name: "Bürgermajster",
            description: "Na somárovi sa nesie Bürgermeister, študenti takto vyjadrovali svoju „úctu“ najvyššiemu predstaviteľovi mesta."
        },
        33: {
            name: "Vojaci",
            description: "Na čele s veliteľom miestnej posádky kráčajú vojaci vyzbrojení puškami."
        },
        34: {
            name: "Mestský bubeník",
            description: "Bubeník v uhorskom kabátci s klobúkom oznamuje mestu nariadenia magistrátu. "
        },
        35: {
            name: "Maďarskí žandári",
            description: "Puškami vyzbrojení kráčajú maďarskí žandári v klobúkoch s perom, ako počas Salamandra v roku 1936."
        },
        36: {
            name: "Kanonieri",
            description: "Netypickí kanonieri, ktorých úlohou nebolo bojovať, ale rozháňať mraky špeciálnym kanónom."
        },
        37: {
            name: "Nočný hlásnik",
            description: "Sebavedomý a rešpektovaný, úlohou hlásnika bolo chrániť život i majetok obyvateľov mesta. "
        },
        38: {
            name: "Živé hodiny",
            description: "Jedna z mnohých štiavnických zaujímavostí, oznamovali presný čas trúbením z veže Nového zámku."
        },
        39: {
            name: "Šašovia",
            description: "Do mesta zavítal cirkus! Mešťanov rozveseľujú šašovia a klauni. Obľúbené postavy fašiangových sprievodov."
        },
        40: {
            name: "Medveď so sprievodcom",
            description: "V sprievode sa pohybujú aj cirkusanti s medveďom, ktorí pozývajú ctené obecenstvo na predstavenie."
        },
        41: {
            name: "Maharadža so sprievodom",
            description: "Podľa legendy si Maharadža so sprievodom prišiel po svojho zadĺženého syna študujúceho v Štiavnici."
        },
        42: {
            name: "Indiáni",
            description: "Obľúbené fašiangové masky Indiánov, ktoré sa dostali až do Salamandrového sprievodu."
        },
        43: {
            name: "Vodníci",
            description: "Rozprávkové bytosti vodníkov sú známe z fašiangových sprievodov, príbehy o ich živote v tajchoch sa však nezachovali."
        },
        44: {
            name: "Pat a Patašón",
            description: "Úsmevné postavy, vysoký a chudý spolu s malým a tučným – filmový pár nazývaný Pat a Patašon."
        },
        45: {
            name: "Léna s Nácom v koši",
            description: "Matka nesie v chrtovom koši svojho dospelého syna Náca na odvod, regrút  v koši veselo fajčí."
        },
        46: {
            name: "Svadba",
            description: "Veselú spoločnosť svadobčanov tvoria harmonikár, starejší, nevesta, ženích, družičky, mládenci, svadobní rodičia a svedkovia. "
        },
        47: {
            name: "Lacný Joško",
            description: "Bosniak, zvaný Lacný Joško, mal celý svoj obchod v krošni zbitej z dosák a ceny zjednával. "
        },
        48: {
            name: "Vtáčnikár",
            description: "Dnes už takmer neznáma profesia vtáčnikára (človeka, ktorý chytal a predával vtáky)."
        },
        49: {
            name: "Motýličiar",
            description: "Obchodník s motýľmi, ktoré sám chytal do sieťky."
        },
        50: {
            name: "Starý horár",
            description: "Za svadobným sprievodom kráča starý horár s puškou."
        },
        51: {
            name: "Poštár a požiarnik",
            description: "Dôležité postavy mestského života, jeden zabezpečoval spojenie Štiavničanov so svetom a druhý chránil mesto pred ničivými požiarmi."
        },
        52: {
            name: "Handrár a Cigáni",
            description: "Handrári zbierali od ľudí staré handry a menili ich za drobnosti či hlinený riad. V sprievode ich nasleduje nespútaná zábava pri cigánskej hudbe, so spevom a tancom. V minulosti súčasť každého fašiangového sprievodu."
        },
        53: {
            name: "Starý lekár so zdravotnou sestrou",
            description: "Nesú teplomer i striekačku,  sú odhodlaní poskytnúť pomoc nielen účinkujúcim, ale aj divákom."
        },
        54: {
            name: "Kojná s guvernantkou a slúžkou",
            description: "V  minulosti bežné povolania žien, slúžiacich v domácnosti štiavnických mešťanov. Starali sa o výchovu a vzdelanie detí i poriadok v dome. "
        },
        55: {
            name: "Život v meste",
            description: "Prehliadka dobových živností a remesiel: kuchárky, cukrárky, pekárky, drotár, kominár, krčmár, obuvník, mäsiar, kováči, tesári, údržbári... povolania, bez ktorých by mesto nezaobišlo. "
        },
        56: {
            name: "Štiavnická Anča",
            description: "Malá parná lokomotíva  so strojvodcom, vlakvedúcim, výhybkárom a koľajárom rachotia mestom."
        },
    }

}