<template>
    <InstallPrompt class="mx-auto w-full md:max-w-[786px]"/>
    <UpdatePrompt class="mx-auto w-full md:max-w-[786px]"/>
    <Nav class="mx-auto w-full md:max-w-[786px]"/>
    <RouterView class="mx-auto pb-20 px-8 min-h-[calc(100vh-142px)] w-full md:max-w-[786px]"/>
    <Footer class="mx-auto w-full md:max-w-[786px]"/>
</template>

<script setup>
import InstallPrompt from "@/components/InstallPrompt.vue";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import {onMounted} from "vue";
import {sendStats} from "@/api";
import {v4} from "uuid";
import UpdatePrompt from "@/components/UpdatePrompt.vue";

onMounted(() => {
    localStorage.setItem("session-id", v4())
    sendStats("OPEN")
})
</script>

<style>

</style>
