<template>
    <div v-if="display"
         class="z-50 fixed md:left-1/2 md:-translate-x-1/2 bottom-0 w-full bg-primary-blue shadow-2xl p-4 flex flex-col justify-center items-center gap-4 text-center text-xl text-white">
        {{ $t('update.info') }}

        <div class="flex gap-4 justify-between items-center text-primary-blue">
            <button @click="update"
                    class="rounded-full bg-white px-8 pt-2 pb-3 border-2 border-white hover:bg-dark-blue hover:text-white font-semibold duration-300">
                {{ $t('update.confirm') }}
            </button>

            <button @click="dismiss"
                    class="rounded-full bg-transparent px-8 pt-2 pb-3 border-2 border-white hover:bg-dark-blue font-semibold text-white duration-300">
                {{ $t('update.decline') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {sendStats} from "@/api";

const display = ref(false);

document.addEventListener('update-app', () => {
    display.value = true
});

navigator.serviceWorker.getRegistration().then(registration => {
    if (registration) {
        registration.update();
    }
});

function update() {
    display.value = false
    sendStats("ACCEPT_UPDATE")
    window.location.reload();
}

function dismiss() {
    display.value = false
    sendStats("DISMIS_UPDATE")
}
</script>
