<template>
    <div v-if="getBrowserIdentifier() !== 'Safari' && display"
         class="z-50 fixed md:left-1/2 md:-translate-x-1/2 bottom-0 w-full bg-primary-blue shadow-2xl p-4 flex flex-col justify-center items-center gap-4 text-center text-xl text-white">
        {{ $t('install.info') }}

        <div class="flex gap-4 justify-between items-center text-primary-blue">
            <button @click="install"
                    class="rounded-full bg-white px-8 pt-2 pb-3 border-2 border-white hover:bg-dark-blue hover:text-white font-semibold duration-300">
                {{ $t('install.confirm') }}
            </button>

            <button @click="dismiss"
                    class="rounded-full bg-transparent px-8 pt-2 pb-3 border-2 border-white hover:bg-dark-blue font-semibold text-white duration-300">
                {{ $t('install.decline') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import {onBeforeMount, ref} from "vue";
import {sendStats} from "@/api";
import {getBrowserIdentifier} from "@/utils";

const display = ref(false);
let installEvent = null;

onBeforeMount(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        installEvent = e;
        display.value = true;
    });
});

function install() {
    sendStats("ACCEPT_INSTALL_PROMPT")
    if (installEvent) {
        installEvent.prompt();
        installEvent.userChoice.then((choice) => {
            display.value = false; // Hide the prompt after user's choice
            if (choice.outcome === 'accepted') {
                sendStats("ACCEPT_INSTALL")
            } else {
                sendStats("DISMISS_INSTALL")
            }
        });
    }
}

function dismiss() {
    display.value = false
    sendStats("DISMIS_INSTALL_PROMPT")
}
</script>
