import sk from './sk';
import en from './en';
import {createI18n} from "vue-i18n";

const resources = {
    en,
    sk,
};

export default createI18n({
    locale: 'sk',
    // fallbackLocale: 'en',
    messages: resources,
});

