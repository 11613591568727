import {createWebHistory, createRouter} from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import( '../views/HomeView.vue' ),
        }, {
            path: '/postavy/:characterIndex?',
            name: 'characters',
            component: () => import( '../views/CharactersView.vue' ),
        }, {
            path: '/aplikacia',
            name: 'app',
            component: () => import( '../views/About.vue' ),
        },
        {
            path: '/ios-instalacia',
            name: 'ios-install',
            component: () => import( '../views/InstallOnIosView.vue' ),
        },
    ]
})

export default router