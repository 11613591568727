import axios from "axios";
import {getBrowserIdentifier, getDeviceType} from "@/utils";

const api = axios.create({
    baseURL: 'https://ss-stats.filo.h10s.eu/api',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

export function sendStats(action) {
    const data = {
        session_id: localStorage.getItem("session-id"),
        environment: location.host,
        action: action,
        browser: getBrowserIdentifier(),
        device: getDeviceType(),
        language: navigator.language,
        screen_width: window.screen.availWidth,
        screen_height: window.screen.availHeight
    }
    try {
        // api.post('/stats', data)
        //     .then((response) => console.debug(response))
        //     .catch((error) => console.warn(error))
    } catch (_) {
    }
}
